import React from 'react';

import { XCircleFillIcon } from '@primer/octicons-react'

import { useTranslation } from 'react-i18next';
import { translations as _ } from "../../locales/i18n";

const ErrorAlert = () => {
    const { t } = useTranslation();

    return (
        <>
            <h4 className="py-3"><XCircleFillIcon className="text-danger" size={24} /> {t(_.error.header)}</h4>
            <div className="alert alert-danger">{t(_.error.note)} <a href="mailto:support@omysport.com">support@omysport.com</a></div>
        </>
    )
}

export default ErrorAlert;
