import axios, { AxiosRequestConfig } from 'axios';

import config from "../config"

import { TelegramPlanOffer, CheckoutSession } from "./types";

const apiUrl = config.basecampHost + "/api/v1/public/stripe";

export class BaseCampService {
    config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json' } };

    public async getTelegramOffers(): Promise<TelegramPlanOffer[]> {
        const resp = await axios.get<TelegramPlanOffer[]>(`${apiUrl}/telegram/plan-offers`, this.config);
        return resp.data;
    }

    public async createCheckoutSession(userId: string | undefined, priceId: string, redirectUri: string): Promise<CheckoutSession> {
        const request = {
            userCode: userId,
            priceId: priceId,
            redirectUri: redirectUri
        };

        const resp = await axios.post<CheckoutSession>(`${apiUrl}/checkout-session`, request, this.config);

        return resp.data;
    }

    public async fulfillCheckoutSession(sessionId: string): Promise<boolean> {
        const resp = await axios.put(`${apiUrl}/checkout-session/${sessionId}`, null, this.config);

        return resp.status === 200;
    }
}
