import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import '@stripe/stripe-js';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ReactPixel from 'react-facebook-pixel';

import 'bootstrap/dist/css/bootstrap.min.css';

import config from "./config"
import PlansView from "./views/plans/PlansView";
import SuccessfulPaymentView from "./views/success/SuccessfulPaymentView";
import ErrorView from './ErrorView';

import './App.css';

Bugsnag.start({
    apiKey: '754e888d8a8778275be9929d46cfdfa8',
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV, // By default, if the URL contains localhost this is set to development.
    // The default value in all other circumstances is production.
    // TODO: need to specify 'staging' on staging
    enabledReleaseStages: ['production', 'staging']
})

ReactPixel.init(config.facebookPixelId);

if (process.env.NODE_ENV !== "development") {
    console.log = () => { };
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

function App() {
    const application = (
        <BrowserRouter>
            <Routes>
                <Route path="/healthcheck" element={<div>true</div>} />
                <Route path="/super-coach/:userId" element={<PlansView />} />
                <Route path="/coach/:userId" element={<PlansView />} />
                <Route path="/success" element={<SuccessfulPaymentView />} />
                <Route path="/cancel/:userId" element={<PlansView />} />
            </Routes>
        </BrowserRouter>
    )

    return ErrorBoundary ? (
        <ErrorBoundary FallbackComponent={ErrorView}>{application}</ErrorBoundary>
    ) : (
            application
        )
}

export default App;
