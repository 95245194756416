var configs : { [env:string]:IConfig; } = {
    "staging": {
        landing: "https://omysports.ai",
        basecampHost: "https://basecamp-stage.omy.run",
        telegramBot: "https://t.me/omy_coach_stage_bot",
        stripePublishableKey: "pk_test_m1qHBaUedMSomK5TTBckt1V600QiSsYbh1",
        facebookPixelId: "",
        yandexMetrika: undefined
    },
    "production": {
        landing: "https://omysports.ai",
        basecampHost: "https://basecamp.omy.run",
        telegramBot: "https://t.me/omy_coach_bot",
        stripePublishableKey: "pk_live_TQXewsLJwV6xBdYF8rVNrYDk00vN0OcvDV",
        facebookPixelId: "241396064208503",
        yandexMetrika: {
          account: 78587004,
          options: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }
        }
    }
};

const reactAppEnv = process.env.REACT_APP_ENV || "staging";

export default configs[reactAppEnv];

export interface IConfig {
    landing: string;
    basecampHost: string;
    telegramBot: string;
    stripePublishableKey: string;
    facebookPixelId: string;
    yandexMetrika: IYandexMetrikaConfig | undefined;
}

export interface IYandexMetrikaConfig {
    account: number;
    options: {
      clickmap: boolean;
      trackLinks: boolean;
      accurateTrackBounce: boolean;
      webvisor: boolean;
    };
}
