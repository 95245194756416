import React, { useState, useEffect } from 'react';

import { PaperAirplaneIcon, ShieldCheckIcon, XCircleFillIcon } from '@primer/octicons-react'

import { useTranslation } from 'react-i18next';
import { translations as _ } from "../../locales/i18n";

import config from "./../../config"
import useQuery from "../../tools/router-query";
import { BaseCampService } from '../../services/BaseCampService';
import SpaceLayout from '../../layouts/SpaceLayout';

import './SuccessfulPaymentView.scss';

const SuccessfulPaymentView = () => {
    const { t } = useTranslation();
    const query = useQuery();
    const redirectUri = query.get('redirect_uri') ?? config.landing;

    const basecamp = new BaseCampService();

    const [fulfilled, setFulfilled] = useState<boolean | null>(null);

    useEffect(() => {
        (async () => {
            var sessionId = query.get('session_id');
            if (!sessionId) {
                setFulfilled(null);
                return;
            }

            try {
                var result = await basecamp.fulfillCheckoutSession(sessionId);
                setFulfilled(result);
            } catch (error) {
                console.error(error)
                setFulfilled(false);
                throw error;
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // second argument prevent to run on EVERY render.

    return (<SpaceLayout>
        <div className="py-3">
            <div className="well">
                {fulfilled === true ?
                    (<h4 className="py-3"><ShieldCheckIcon className="text-success" size={24} /> {t(_.successfulPayment.success)}</h4>)
                    :
                    fulfilled === false ?
                        (
                            <>
                                <h4 className="py-3"><XCircleFillIcon className="text-danger" size={24} /> {t(_.successfulPayment.fail)}</h4>
                                <div className="alert alert-danger">{t(_.successfulPayment.failNote)} <a href="mailto:support@omysport.com">support@omysport.com</a></div>
                            </>
                        )
                        :
                        (<div className="d-flex align-items-center">
                            <div className="spinner-grow mr-3" role="status" aria-hidden="true"></div>
                            <span>Processing...</span>
                        </div>)
                }
                {fulfilled && <div className="py-3">
                    <a href={decodeURIComponent(redirectUri)} className="btn btn-primary"><PaperAirplaneIcon className="text-white" size={16} /> {t(_.successfulPayment.return)}</a>
                </div>}
            </div>
        </div>
    </SpaceLayout>)
}

export default SuccessfulPaymentView;
