import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Stripe, loadStripe } from "@stripe/stripe-js";
import { CheckIcon } from "@primer/octicons-react";
import { useTranslation } from "react-i18next";
import { translations as _ } from "../../locales/i18n";
import ReactPixel from "react-facebook-pixel";

import { BaseCampService } from "../../services/BaseCampService";
import useQuery from "../../tools/router-query";
import { TelegramPlanOffer, SubscriptionPeriods } from "../../services/types";
import SpaceLayout from "../../layouts/SpaceLayout";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import ErrorPaymentAlert from "../../components/alerts/ErrorPaymentAlert";

import config from "../../config";

import "./PlansView.scss";

interface IParams {
    userId: string | undefined;
}

const PlansView = () => {
    let { userId }: IParams = useParams();
    const { t } = useTranslation();
    const redirectUri = useQuery().get("redirect_uri") ?? config.landing;

    const basecamp = new BaseCampService();

    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [offers, setOffers] = useState<TelegramPlanOffer[] | null>(null);
    const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showPaymentError, setShowPaymentError] = useState<boolean>(false);
    const [pricePerMonth, setPrice] = useState(0);

    const telegramOffersDescription = {
        [SubscriptionPeriods.Monthly]: {
            title: t(_.telegram.offers.monthly.title),
            titleSub: t(_.telegram.offers.monthly.titleRecurrent),
            hint: t(_.telegram.offers.monthly.hint),
            hints: [
                //t(_.telegram.offers.monthly.length),
                t(_.telegram.offers.feat13),
                t(_.telegram.offers.feat1),
                t(_.telegram.offers.feat2),
                t(_.telegram.offers.feat7),
                t(_.telegram.offers.feat8),
            ],
        },
        [SubscriptionPeriods.Quarterly]: {
            title: t(_.telegram.offers.quarterly.title),
            titleSub: t(_.telegram.offers.quarterly.titleRecurrent),
            hint: t(_.telegram.offers.quarterly.hint),
            hints: [
                //t(_.telegram.offers.quarterly.length),
                t(_.telegram.offers.feat13),
                t(_.telegram.offers.feat1),
                t(_.telegram.offers.feat2),
                t(_.telegram.offers.feat7),
                t(_.telegram.offers.feat8),
            ],
        },
        [SubscriptionPeriods.SemiAnnually]: {
            title: t(_.telegram.offers.semiannual.title),
            titleSub: t(_.telegram.offers.semiannual.titleRecurrent),
            hint: t(_.telegram.offers.semiannual.hint),
            hints: [
                //t(_.telegram.offers.semiannual.length),
                t(_.telegram.offers.feat13),
                t(_.telegram.offers.feat1),
                t(_.telegram.offers.feat2),
                t(_.telegram.offers.feat7),
                t(_.telegram.offers.feat8),
            ],
        },
        [SubscriptionPeriods.Annually]: {
            title: t(_.telegram.offers.annual.title),
            titleSub: t(_.telegram.offers.annual.titleRecurrent),
            hint: t(_.telegram.offers.annual.hint),
            hints: [
                //t(_.telegram.offers.annual.length),
                t(_.telegram.offers.feat13),
                t(_.telegram.offers.feat1),
                t(_.telegram.offers.feat2),
                t(_.telegram.offers.feat7),
                t(_.telegram.offers.feat8),
            ],
        },
    };

    useEffect(() => {
        (async () => {
            const s = await loadStripe(config.stripePublishableKey);
            setStripe(s);

            try {
                var offers = await basecamp.getTelegramOffers();
                offers = sort(offers);
                setOffers(offers);
                setPrice(offers[0].price);
                if (offers && config.facebookPixelId) {
                    ReactPixel.track("ViewContent");
                }
            } catch (error) {
                console.error(error);
                setShowError(true);
                throw error;
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // second argument prevent to run on EVERY render.

    const sort = (offers: TelegramPlanOffer[]) => {
        let newOffers: TelegramPlanOffer[] = [];

        const o1 = find(SubscriptionPeriods.Monthly, offers);
        if (o1) {
            newOffers.push(o1);
        }

        const o2 = find(SubscriptionPeriods.Quarterly, offers);
        if (o2) {
            newOffers.push(o2);
        }

        const o3 = find(SubscriptionPeriods.SemiAnnually, offers);
        if (o3) {
            newOffers.push(o3);
        }

        const o4 = find(SubscriptionPeriods.Annually, offers);
        if (o4) {
            newOffers.push(o4);
        }

        return newOffers;
    };

    const find = (period: SubscriptionPeriods, source: TelegramPlanOffer[]) => {
        const offer = source.find((o) => {
            return o.subscriptionPeriod === period;
        });

        return offer ?? null;
    };

    const checkout = async (priceId: string) => {
        setIsCheckingOut(true);
        try {
            var checkoutSession = await basecamp.createCheckoutSession(
                userId,
                priceId,
                redirectUri
            );

            if (offers && config.facebookPixelId) {
                ReactPixel.track("InitiateCheckout");
            }
            stripe?.redirectToCheckout({
                sessionId: checkoutSession.sessionId,
            });
        } catch (error: any) {
            console.error(error);
            if (
                error.response.status === 400 &&
                error.response.data === "User already has subscription plan."
            ) {
                setShowPaymentError(true);
            } else {
                setShowPaymentError(false);
            }
            setShowError(true);
            throw error;
        } finally {
            setIsCheckingOut(false);
        }
    };

    const renderPrice = (offer: TelegramPlanOffer) => {
        let oldPrice = "";
        let monthlyPrice = "";
        const fullPrice = offer.price.toFixed(2);
        const { subscriptionPeriod } = offer;

        switch (subscriptionPeriod) {
            case SubscriptionPeriods.Monthly:
                monthlyPrice = offer.price.toFixed(2);
                break;
            case SubscriptionPeriods.Quarterly:
                oldPrice = (pricePerMonth * 3).toFixed(2);
                monthlyPrice = (offer.price / 3).toFixed(2);
                break;
            case SubscriptionPeriods.SemiAnnually:
                oldPrice = (pricePerMonth * 6).toFixed(2);
                monthlyPrice = (offer.price / 6).toFixed(2);
                break;
            case SubscriptionPeriods.Annually:
                oldPrice = (pricePerMonth * 12).toFixed(2);
                monthlyPrice = (offer.price / 12).toFixed(2);
                break;
        }

        if (
            subscriptionPeriod === SubscriptionPeriods.Quarterly ||
            subscriptionPeriod === SubscriptionPeriods.SemiAnnually ||
            subscriptionPeriod === SubscriptionPeriods.Annually
        ) {
            return (
                <div className="bestprice">
                    <div className="profit">
                        <span className="old">&euro;{oldPrice}</span>
                        <span className="new">&euro;{fullPrice}</span>
                    </div>
                    <div className="month-price">
                        &euro;{monthlyPrice} per month
                    </div>
                </div>
            );
        } else {
            return (
                <div className="bestprice">
                    <div className="first"> &euro;{monthlyPrice}</div>
                </div>
            );
        }
    };

    return (
        <SpaceLayout>
            <div>
                {showError ? (
                    showPaymentError ? (
                        <ErrorPaymentAlert />
                    ) : (
                        <ErrorAlert />
                    )
                ) : (
                    <>
                        <div className="main-call">
                            <h2>{t(_.telegram.leadTitle)}</h2>
                        </div>
                        <ul className="premium col-md-6 col-sm-12">
                            <li>{t(_.premium.adv1)}</li>
                            <li>{t(_.premium.adv2)}</li>
                            <li>{t(_.premium.adv3)}</li>
                            <li>{t(_.premium.adv4)}</li>
                            <li>{t(_.premium.adv5)}</li>
                        </ul>
                    </>
                )}

                <div className="row p-2 offers">
                    {offers?.map((offer, i) => {
                        const offerDesc =
                            telegramOffersDescription[offer.subscriptionPeriod];
                        return (
                            <div
                                className="offers__item col-12 col-md-6 col-lg-4"
                                key={`offer${i}`}
                            >
                                <div className="offer">
                                    <div className="pb-1">
                                        <h3>{offerDesc.title}</h3>
                                    </div>
                                    <div className="pb-1">
                                        {renderPrice(offer)}
                                    </div>
                                    <div className="offer__action pb-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                checkout(offer.priceId)
                                            }
                                            disabled={isCheckingOut}
                                        >
                                            {isCheckingOut ? (
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : offer.autoProlongation ? (
                                                t(_.telegram.buyButtonRecurrent)
                                            ) : (
                                                t(_.telegram.buyButton) +
                                                " " +
                                                offerDesc.title
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </SpaceLayout>
    );
};

export default PlansView;
