import React from 'react';

import './SpaceLayout.scss';

interface Props {
    children: React.ReactNode;
}

const SpaceLayout = ({ children }: Props) => {
    return (<div className="h-100" style={{ backgroundColor: "#f5f9fe" }}>
        <main className="flex-shrink-0 container">
            <div className="header py-3">
                <div className="header__logo logo"></div>
                <div className="header__title company-title">OMY! Sports</div>
            </div>
            {children}
        </main>
        {/* <footer className="footer mt-auto py-3">
    <div className="container">
        <span className="text-muted">OMY! Sports</span>
    </div>
</footer> */}
    </div>)
};

export default SpaceLayout;
