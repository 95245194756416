export interface TelegramPlanOffer {
    title: string;
    price: number;
    priceId: string;
    currency: string;
    interval: string;
    intervalCount: number;
    subscriptionPeriod: SubscriptionPeriods;
    descriptionLines: string[];
    autoProlongation: boolean;
}

export interface CheckoutSession {
    sessionId: string;
}

export enum SubscriptionPeriods {
    /// <summary>
    /// Once a month
    /// </summary>
    Monthly = 1,

    /// <summary>
    /// Once a quarter (three month)
    /// </summary>
    Quarterly = 2,

    /// <summary>
    /// Once in 6 months
    /// </summary>
    SemiAnnually = 4,

    /// <summary>
    /// Once a year
    /// </summary>
    Annually = 3
}

export enum CheckoutSessionType {
    Default = 0,
    Telegram = 1
}
